// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainContainer {
  min-height: 100vh;
  width: 100%;
  display: grid;
  justify-items: center;
}

/*there is an absolute div in app.js for overall relative positoning, but this one is still needed for background color when modal pops up*/
.modalBackground {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  padding-top: 0;
}

/*this gives an overall padding for the whole app*/
.app {
  width: 90%;
}

/*plus and minus icons are used in Cart.js and in SingleItems.js*/
.plusMinusIcons {
  font-size: 1.6rem;
  color: rgba(0, 0, 0, 0.6);
}

/*styling for larger screens*/
@media (min-width: 90rem) {
  .app {
    /*increases margin on sides*/
    width: 60%;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,qBAAqB;AACvB;;AAEA,2IAA2I;AAC3I;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,8BAA8B;EAC9B,YAAY;EACZ,cAAc;AAChB;;AAEA,kDAAkD;AAClD;EACE,UAAU;AACZ;;AAEA,iEAAiE;AACjE;EACE,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA,6BAA6B;AAC7B;EACE;IACE,4BAA4B;IAC5B,UAAU;EACZ;AACF","sourcesContent":[".mainContainer {\n  min-height: 100vh;\n  width: 100%;\n  display: grid;\n  justify-items: center;\n}\n\n/*there is an absolute div in app.js for overall relative positoning, but this one is still needed for background color when modal pops up*/\n.modalBackground {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(0, 0, 0, 0.5);\n  z-index: 999;\n  padding-top: 0;\n}\n\n/*this gives an overall padding for the whole app*/\n.app {\n  width: 90%;\n}\n\n/*plus and minus icons are used in Cart.js and in SingleItems.js*/\n.plusMinusIcons {\n  font-size: 1.6rem;\n  color: rgba(0, 0, 0, 0.6);\n}\n\n/*styling for larger screens*/\n@media (min-width: 90rem) {\n  .app {\n    /*increases margin on sides*/\n    width: 60%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
