// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* terms */
.TermsModal_termsContainer__6BziJ {
  top: 17.5vh;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  background: #fff;
  height: 65vh;
  width: 100%;
  margin: auto;
  max-width: 800px;
  box-shadow: 0.25rem 0rem 0.4rem 0 rgba(93, 91, 91, 0.3);
  border-radius: 0.5rem;
  /*theses media queries dont really belong at the bottom of the page becuase they are special settings for the modal*/
  @media screen and (max-width: 500px) {
    height: 100vh;
    top: 0;
    border-radius: 0;
  }
  @media screen and (max-height: 900px) {
    height: 100vh;
    top: 0;
    border-radius: 0;
  }
}

.TermsModal_disclaimerContainer__scNKH {
  position: absolute;
  background: #fff;
  top: 1.3rem;
  left: 0;
  height: 84%;
  width: 90%;
  zindex: 999;
  margin: 5%;
  borderradius: 0.2rem;
  overflow: auto;
}

.TermsModal_exit__\\+p7lg {
  position: absolute;
  top: 0px;
  right: 5px;
  font-weight: 400;
  font-size: 1.2rem;
  border: none;
  z-index: 9999;
}

.TermsModal_exit__\\+p7lg p {
  color: grey;
  margin-top: 0.8rem;
  margin-bottom: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/TermsModal/TermsModal.module.css"],"names":[],"mappings":"AAAA,UAAU;AACV;EACE,WAAW;EACX,SAAS;EACT,2BAA2B;EAC3B,eAAe;EACf,gBAAgB;EAChB,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,uDAAuD;EACvD,qBAAqB;EACrB,oHAAoH;EACpH;IACE,aAAa;IACb,MAAM;IACN,gBAAgB;EAClB;EACA;IACE,aAAa;IACb,MAAM;IACN,gBAAgB;EAClB;AACF;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,WAAW;EACX,OAAO;EACP,WAAW;EACX,UAAU;EACV,WAAW;EACX,UAAU;EACV,oBAAoB;EACpB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,gBAAgB;EAChB,iBAAiB;EACjB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["/* terms */\n.termsContainer {\n  top: 17.5vh;\n  left: 50%;\n  transform: translateX(-50%);\n  position: fixed;\n  background: #fff;\n  height: 65vh;\n  width: 100%;\n  margin: auto;\n  max-width: 800px;\n  box-shadow: 0.25rem 0rem 0.4rem 0 rgba(93, 91, 91, 0.3);\n  border-radius: 0.5rem;\n  /*theses media queries dont really belong at the bottom of the page becuase they are special settings for the modal*/\n  @media screen and (max-width: 500px) {\n    height: 100vh;\n    top: 0;\n    border-radius: 0;\n  }\n  @media screen and (max-height: 900px) {\n    height: 100vh;\n    top: 0;\n    border-radius: 0;\n  }\n}\n\n.disclaimerContainer {\n  position: absolute;\n  background: #fff;\n  top: 1.3rem;\n  left: 0;\n  height: 84%;\n  width: 90%;\n  zindex: 999;\n  margin: 5%;\n  borderradius: 0.2rem;\n  overflow: auto;\n}\n\n.exit {\n  position: absolute;\n  top: 0px;\n  right: 5px;\n  font-weight: 400;\n  font-size: 1.2rem;\n  border: none;\n  z-index: 9999;\n}\n\n.exit p {\n  color: grey;\n  margin-top: 0.8rem;\n  margin-bottom: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"termsContainer": `TermsModal_termsContainer__6BziJ`,
	"disclaimerContainer": `TermsModal_disclaimerContainer__scNKH`,
	"exit": `TermsModal_exit__+p7lg`
};
export default ___CSS_LOADER_EXPORT___;
